/*
        SUMMARY
            0 - Const / Variables
            1 - Function
*/


/*---------------------/ 1 - Const - Variables /-------------------*/


/*---------------------/ 2 - Functions /-------------------*/

window.onload = function () {
    const itemBoxes = document.querySelectorAll('.item-box-size');
    let maxHeight = 0;

    itemBoxes.forEach(box => {
        const boxHeight = box.offsetHeight;
        if (boxHeight > maxHeight) {
            maxHeight = boxHeight;
        }
    });

    itemBoxes.forEach(box => {
        box.style.height = maxHeight + 'px';
    });

    const boxPlan = document.querySelectorAll('.box-plan');
    let maxHeight1 = 0;

    boxPlan.forEach(box => {
        const boxHeight1 = box.offsetHeight;
        if (boxHeight1 > maxHeight1) {
            maxHeight1 = boxHeight1;
        }
    });

    boxPlan.forEach(box => {
        box.style.height = maxHeight1 + 'px';
    });
};
